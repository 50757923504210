<template>
  <div style="background-color: #f7f4eb">
    <template v-if="loaded">
      <v-container fluid>
        <v-row>
          <v-col class="pa-0">
            <v-row no-gutters>
              <v-col cols="12" md="6" class="py-6 column-recipe-texts">
                <div class="title-recipe">
                  <h1 class="subtitles-a">{{ recipe.name }}</h1>
                </div>
                <div v-html="recipe.process" class="recipe-process"></div>

                <template v-for="(recipeP, rp) in recipe.products">
                  <div :key="rp" class="benefits-product">
                    <h2>{{ recipeP.name }} Benefits</h2>
                    <ul class="benefits-list">
                      <li
                        v-for="(benefit, b) in recipeP.benefitsArray"
                        v-bind:key="b"
                      >
                        <p class="mb-0">{{ benefit.value }}</p>
                      </li>
                    </ul>
                  </div>
                </template>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-img
                  :src="
                    recipe.cover !== null
                      ? 'https://www.lakewaycms.actstudio.xyz' +
                        recipe.cover.path
                      : ''
                  "
                  eager
                  class="img-recipe"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
export default {
  name: "Recipe",
  data() {
    return {
      recipe: {
        cover: {
          path: "",
        },
      },
      loaded: false,
    };
  },
  beforeMount: function () {
    fetch("https://www.lakewaycms.actstudio.xyz/api/collections/get/Recipes", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { url: this.$route.params.url },
        populate: 1, // resolve linked collection items
      }),
    })
      .then((res) => res.json())
      .then((res) => (this.recipe = res.entries[0]))
      .then((this.loaded = true));
  },
};
</script>

<style lang="scss" scoped>
$xxl: 3629px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

.title-recipe {
  margin-top: 30px;
  margin-bottom: 30px;

  @media screen and(max-width: $xl) {
    margin-top: 18px;
  }

  @media screen and(max-width: $md) {
    margin-top: 23px;
  }
}

.benefits-product {
  margin-top: 30px;

  @media screen and(max-width: $md) {
    margin-bottom: 23px;
  }
}

.benefits-list {
  margin-top: 15px;
}

.recipe-process {
  max-width: 800px;

  @media screen and(max-width: $xl) {
    max-width: 500px;
  }

  @media screen and(max-width: $lg) {
    max-width: 484px;
  }
}

.column-recipe-texts {
  padding-left: 70px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;

  @media screen and(max-width: $xl) {
    padding-left: 55px;
  }

  @media screen and(max-width: $sm) {
    padding: 0 44px;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 20px;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;

    @media screen and(max-width: $xl) {
      width: 8px;
    }
  }

  &::-webkit-scrollbar-button,
  ::-webkit-scrollbar-button:increment {
    display: none;
  }
}

.img-recipe {
  height: calc(100vh - 100px);

  @media screen and(max-width: $md) {
    height: 520px;
  }

  @media screen and(max-width: $sm) {
    height: 400px;
  }
}
</style>